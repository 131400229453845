<!-- The LegalPolicies view of the Virtual-Showroom Website. -->
<!-- @author Yasien Kashef -->
<!-- @version 1.0.0 -->
<template>
  <div class="legal-policies">
    <!-- Back Button -->
    <button @click="back()">
      <img src="@/assets/icons/left_arrow.png">
      <span>Zurück</span>
    </button>
    <!-- iFrame -->
    <iframe :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
/**
 * LegalPolicies view of the Virtual-Showroom Website.
 * Gets routed to when "Impressum" or "Datenschutz" is clicked.
 * Display the legal notice or policy website respectively to the clicked link in an iFrame.
 * @author Yasien Kashef
 * @version 1.0.0
 */

export default {
  name: "LegalPolicies",
  props: {
    url: String
  },
  methods: {
    back() {
      this.$router.go(-1);  // Routes back to the history url.
    }
  },
  mounted() {
    window.scrollTo(0,0);   // To override any previous scroll action.
  }
};
</script>

<!-- Notice "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.legal-policies {
  width: 100%;
  height: 100%;
  overflow: hidden;

  button {
    display: flex;
    align-items: center;
    height: 5vh;
    margin: 10px;
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;

    img {
      width: 15px;
    }

    span {
      padding: 10px;
      font-family: "Brandon Grotesque";
      font-size: 1.4rem;
      color: rgba(135, 135, 135, 1);
    }
  }

  iframe {
    width: 100%;
    height: 93vh;
  }
}
</style>
